//@flow
import React from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import {useState} from 'react';

import * as Tone from 'tone';

import {styled} from 'dcme-style/core';
import {Box} from 'dcme-style/layout';
import {Flex} from 'dcme-style/layout';
// import {H3} from 'dcme-style';
// import {Paper} from 'dcme-style';
// import {Modal} from 'dcme-style';
// import {Button} from 'dcme-style/affordance';
import {Clickable} from 'dcme-style/affordance';
// import {Divider} from 'dcme-style/affordance';
import {Icon} from 'dcme-style/affordance';
import {Paper} from 'dcme-style';
// import {Link} from 'dcme-style/affordance';
// import {Text} from 'dcme-style/affordance';
// import {Select} from 'dcme-style/affordance';


import useParcelState from 'react-dataparcels/useParcelState';
import useParcelForm from 'react-dataparcels/useParcelForm';
import ParcelBoundary from 'react-dataparcels/ParcelBoundary';
// import asNode from 'react-dataparcels/asNode';
// import translate from 'react-dataparcels/translate';
// import ParcelDrag from 'react-dataparcels-drag';

// import FloatyBox from 'react-floatybox';
// import Point from 'react-floatybox/Point';

import map from 'unmutable/map';
import pipeWith from 'unmutable/pipeWith';
import range from 'unmutable/range';
import reverse from 'unmutable/reverse';

// effects

// var freeverb = new Tone.Freeverb(0.1, 10000).toMaster();
// var vib = new Tone.Vibrato(3, 0.2).connect(delay);
// var cheby = new Tone.Chebyshev(1).connect(vib);
// var trem = new Tone.Tremolo(5, 1).connect(delay).start();

// function updateTime() {
//   requestAnimationFrame(updateTime)
//   document.querySelector('#seconds').textContent = Tone.Transport.seconds.toFixed(2)
// }
// updateTime()

// @flow
// import Helmet from 'react-helmet';
import {Theme} from 'dcme-style/theme';
import {DarkTheme} from 'dcme-style/theme';

type Props = {
    children: *
};

const theme = DarkTheme({
    bg: '#000000',
    copy: '#FFFFFF',
    link: '#49B75A',
    touch: '#ffffff',
    hover: '#222222',
    focus: '#333333',
    panelButton: '#d86d56',
    panelButtonActive: '#FFFFFF',
    primary: '#f38a72',
    primaryLight: '#ffbeb0',
    primaryDeep: '#bd3232',
    overBg: {
        copy: '#000000',
        hover: '#222222',
        focus: '#333333'
    }
});

const Frame = styled.div`
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    background-color: ${props => props.theme.colors.primary};
    overflow: hidden;
    font-family: ${props => props.theme.fonts.monospace};
    position: relative;
`;

const FrameLayer = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
`;

const Panel = styled.div`
    width: 33.4vh;
    max-width: 160px;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    background-color: ${props => props.theme.colors.bg};
`;

const PanelButton = styled(({icon, ...props}) => <Clickable {...props}><Icon icon={icon} /></Clickable>)`
    width: 16.6vh;
    height: 16.6vh;
    font-size: 8vh;
    padding: 0;

    @media (min-height: 480px) {
        width: 80px;
        height: 80px;
        font-size: 40px;
    }

    text-align: center;
    color: ${props => props.theme.colors[props.active ? 'panelButtonActive' : 'panelButton']};

    &:hover {
        background-color: ${props => props.theme.colors.hover};
    }

    &:focus {
        background-color: ${props => props.theme.colors.focus};
    }
`;

const HoverButton = styled(({icon, ...props}) => <Clickable {...props}><Icon icon={icon} /></Clickable>)`
    width: 16.6vh;
    height: 16.6vh;
    font-size: 8vh;

    @media (min-height: 480px) {
        width: 80px;
        height: 80px;
        font-size: 40px;
    }

    text-align: center;
    color: ${props => props.theme.colors.overBg.copy};

    &:hover {
        color: ${props => props.theme.colors.overBg.hover};
    }

    &:focus {
        color: ${props => props.theme.colors.overBg.focus};
    }
`;

const ToggleSlide = styled.div`
    width: 16.6vh;
    height: 16.6vh;
    max-width: 80px;
    max-height: 80px;
    bottom: 0;
    left: 0;
    position: absolute;
`;

const TogglePanel = styled.div`
    width: 16.6vh;
    height: 16.6vh;
    max-width: 80px;
    max-height: 80px;
    bottom: 0;
    right: 0;
    position: absolute;
`;

const GridPos = styled.div`
    left: ${props => props.x}px;
    top: ${props => props.y}px;
    position: absolute;
`;

const NoteSvg = styled.svg`
    overflow: visible;
    display: block;
    fill: currentColor;
`;

const Note = styled(props => {
    let {pitch, time, duration} = props.noteParcel.value;
    let len = `${duration * 0.5}`;

    return <GridPos
        className={props.className}
        y={time}
        x={pitch * 0.05}
     >
        <NoteSvg viewBox="0 0 24 24">
            <path d="m0,12l12,-12l12,12l-12,12l-12,-12z" />
            <path d={`m0,12l12,12l12,-12l0,${len}l-12,12l-12,-12l0,-${len}z`} fill="#bd3232" />
        </NoteSvg>
     </GridPos>
})`
    width: 10vh;
    max-width: 50px;
    cursor: pointer;
`;

//
// APPLICATION COMPONENTS
//

export const MosfezSequence = () => {

    let [notesParcel] = useParcelForm({
        value: () => {
            return [
                {
                    time: 0,
                    pitch: 3000,
                    duration: 20,
                    velocity: 1
                },
                {
                    time: 48,
                    pitch: 4000,
                    duration: 20,
                    velocity: 1
                },
                {
                    time: 96,
                    pitch: 5000,
                    duration: 20,
                    velocity: 1
                },
                {
                    time: 192,
                    pitch: 6000,
                    duration: 20,
                    velocity: 1
                }
            ]
        }
        // beforeChange: (value) => {
        //     return sortBy(note => note.time)(value);
        // }
    });

    let tuneRef = useRef();
    tuneRef.current = notesParcel.value;

    useEffect(() => {

        let delay = new Tone.FeedbackDelay('24i', 0.5).toMaster();
        delay.wet.value = 0.2;

        // let instrument = new Tone.Sampler({
        //     "A5" : "https://tonejs.github.io/examples/audio/salamander/A5.mp3"
        // }).connect(delay);

        let instrument = new Tone.Synth({
            oscillator: {
                type: 'triangle'
            },
            envelope: {
                attack: 0.005,
                decay: 0.1,
                sustain: 1,
                release: 0.005
            },
            portamento: 0.2
        }).connect(delay);

        var part = new Tone.Part((time, event) => {
            instrument.triggerAttackRelease(event.note, event.dur, time)
        });

        part.removeAll();

        tuneRef.current.forEach((event) => {
            part.add({
                time: `${event.time}i`,
                note: Math.pow(2,(event.pitch * 0.001))*27.5,
                dur: `${event.duration}i`,
                velocity: event.velocity
            });
        });

        part.start(0);

        Tone.Transport.bpm.value = 120;

        return () => {
            // pattern.dispose();
            part.dispose();
            instrument.dispose();
            delay.dispose();
        };
    }, []);

    // TODO measure window height, pass down as context, use it to adjust stuff...

    return <Flex
        justifyContent="center"
        alignItems="center"
        height="100vh"
    >
        <Theme theme={theme}>
            <Frame>
                <NoteGrid notesParcel={notesParcel} />
                <ToggleSlide>
                    <HoverButton icon="zoom-in" />
                </ToggleSlide>
                <TogglePanel>
                    <HoverButton icon="options" />
                </TogglePanel>
                <Panel>
                    <PanelButton active icon="arrow-up-left2" />
                    <PanelButton icon="pencil" />
                    <PanelButton icon="magic-wand" />
                    <PanelButton icon="bin" />
                    {/*<PanelButton icon="copy" />*/}

                    <PanelButton active icon="play3" onClick={() => Tone.Transport.start()} />
                    <PanelButton icon="pause2" />
                    <PanelButton icon="stop2" onClick={() => Tone.Transport.stop()} />
                    <PanelButton icon="loop" />


                    {/*<PanelButton icon="music" />*/}
                    <PanelButton icon="undo2" />
                    <PanelButton icon="redo2" />
                    <PanelButton icon="equalizer" />
                    <PanelButton icon="options" />
                    {/*<PanelButton icon="enlarge2" />*/}
                </Panel>
                {/*<Box>
                    <Touch onClick={() => Tone.Transport.start()}>start <Icon icon="pencil" /> <Icon icon="undo2" /> <Icon icon="zoom-in" /> <Icon icon="bin" /></Touch>
                    <Touch onClick={() => Tone.Transport.stop()}>stop</Touch>
                    <Touch onClick={() => Tone.Transport.bpm.value = 160}>fast</Touch>
                    <Touch onClick={() => Tone.Transport.bpm.value = 120}>normal</Touch>
                </Box>*/}
                {/**/}
            </Frame>
        </Theme>
    </Flex>;
};

const NoteGrid = (props) => {
    let {notesParcel} = props;
    let scale = 20;

    return <FrameLayer>
        {notesParcel.toArray((noteParcel) => {
            return <Note key={noteParcel.key} noteParcel={noteParcel} />;
        })}
    </FrameLayer>;
};
